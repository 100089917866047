<template>
  <div class="min-h-screen">
    <slot name="app-before" />
    <div id="app-before"/>
    <div class="flex flex-col">
      <slot name="header">
        <PageNavbar />
      </slot>
      <LazyAuthModal />
      <div class="sm:min-h-[calc(100vh-360px)]">
        <slot />
        <div class="fixed right-[13px] bottom-3 flex flex-col gap-2">
          <LazyUButton
            icon="i-simple-icons-messenger"
            size="lg"
            color="gray"
            variant="outline"
            :to="MESSENGER_TECHMELY"
            target="_blank"
            aria-label="Liên hệ tới techmely"
            rel="noopener noreferrer nofollow"
          />
        </div>
      </div>
      <slot name="footer">
        <LazyPageFooter />
      </slot>
    </div>
    <slot name="app-after" />
    <div id="app-after"/>
  </div>
</template>

<script lang="ts" setup>
import { MESSENGER_TECHMELY } from "~/constants";
import { typesafeI18n } from "~/locales/i18n-vue";

const runtimeConfig = useRuntimeConfig();
const appConfig = useAppConfig();
const { path } = useRoute();
const { LL, locale } = typesafeI18n();

const canonical = appConfig.url + path;
const title = LL.value.seo_home_title();
const description = LL.value.seo_home_description();

useSeoMeta({
  title,
  description,
  robots: "index,follow",

  twitterSite: "@techmely",
  twitterCreator: "@techmely",
  twitterCard: "summary_large_image",

  ogUrl: canonical,
  ogLocale: locale.value,
  ogSiteName: "KungFuTech",
});

useHead({
  link: [
    {
      rel: "canonical",
      href: canonical,
    },
  ],
  script: [
    {
      type: "application/ld+json",
      innerHTML: JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Person",
        name: appConfig.author.name,
        jobTitle: "Founder",
        sameAs: appConfig.organization.members[1].relatedLinks,
      }),
    },
    {
      key: "gtag-config",
      id: "gtag-config",
      type: "text/partytown",
      innerHTML: `
          window.dataLayer = window.dataLayer || [];
          gtag = function(){dataLayer.push(arguments)};
          gtag('js', new Date());
          gtag('config', '${runtimeConfig.public.gaMeasurementId}');
        `,
    },
    {
      key: "GTM",
      type: "text/partytown",
      async: true,
      src: `https://www.googletagmanager.com/gtm.js?id=${runtimeConfig.public.gtmID}`,
    },
  ],
  noscript: [
    {
      tagPosition: "bodyOpen",
      innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${runtimeConfig.public.gtmID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
    },
  ],
});
</script>
